.button1 {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color:#7F00FF;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}

.button1:hover

    {
        background-color: white;
        color:#7F00FF;
        cursor: pointer;
        text-decoration:none;
        border:1px;
        border-color:blueviolet;
        }
  