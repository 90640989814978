
.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: auto;
  text-align: center;
  font-family: arial;
  background-color:whitesmoke;
  color: #008080;
}
 
.title {
  color:#008080;
  font-size: 18px;
}

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: #008080;
  background-color:white;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

/* button:hover, a:hover {
    background-color: white;
    color: black;
  opacity: 0.8;
} */

.shi{

  padding-left: 57px;
}