.he {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 1px;
    color: white;
    background-color:#7F00FF;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    
}

.hee
{
    background-color:#7F00FF;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    width:auto;
    font-size: medium;

}

@media screen and (max-width: 600px) {
  
            .hee,
            .he
             {
                width: 45%;
                font-size: x-small;

            }
        }