*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.div
{
  position: relative;
  padding: 124px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

}



.App {
  /* background-color: rgba(244, 244, 244, 1); */
  background-color: #e9f8f0;
}

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.page1,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


