.cc22
{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(231, 235, 235);
    color: rgb(18, 17, 17);
    text-align: center;
    padding-left: 75px;
}

.cardProfile {
    
    max-width: 100%;
    margin: auto;
    padding: 10px;
    text-align: center;
      font-family: arial;
      border-radius: 5px ;
      
      font-size: 12px;
    }
     
    