.shi2
{
    padding-left: 76px;
}

.cardProfile {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 80%;
    margin: auto;
    padding: 10px;
    text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
      font-size: 26px;
    }
     
    .title {
      color:#008080;
      font-size: 18px;
      margin: auto;
    }
    .shi1222{
  
      padding-left: 50px;
     
    }