.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 80%;
  margin: auto;
  padding: 10px;
    text-align: center;
    font-family: arial;
    border-radius: 5px ;
    background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));

  }
  .mainContent{
    max-width: 80%;
    
    
    
  }
   
  .shi1{

    padding-left: 50px;
  }
  .title {
    color:#008080;
    font-size: 18px;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: auto;
      text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right,rgb(199, 241, 245),  rgb(242, 212, 248));
      padding: 20px;
      color:rgb(137, 33, 235);
      font-size: medium;
    }
    .card7 {
  
  display:none;
  
    }
    
    .card1 {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;  
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      max-width: 80%;
      margin: auto;
      padding: 10px;
        text-align: center;
        font-family: arial;
        border-radius: 5px ;
        background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
    
        
        
      }
  
      .card2 {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;  
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 100%;
        margin: auto;
          text-align: center;
          font-family: arial;
          border-radius: 5px ;
          background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
          
          
        }
        .card3 {
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;  
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          max-width: 100%;
          margin: auto;
            text-align: center;
            font-family: arial;
            border-radius: 5px ;
            background-image: linear-gradient(to bottom right, rgb(116, 219, 228), rgb(244, 244, 241));
            font-size: medium;
            padding: 20px;
            color:rgb(4, 104, 108);
            
          }
    .mainContent{
      max-width: 80%;
      
      
      
    }
    
    .title {
      color: grey;
      font-size: 18px;
    }
    