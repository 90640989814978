@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
        padding: 0px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
  
  
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.div
{
  position: relative;
  padding: 124px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

}



.App {
  /* background-color: rgba(244, 244, 244, 1); */
  background-color: #e9f8f0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.page1,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}



#header {
    position: absolute;
    width: 220px;
    display:flex;
    
  }
  #header .pro-sidebar {
    height: 100%;
    position:fixed;
    
  }
  #header .closemenu {
    color: #ffffff;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;
    top: 55px;
    cursor: pointer;
  }
  #header .pro-sidebar {
    width: 255px;
    min-width: 255px;
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  #header .pro-sidebar-inner {
    background-color: #7F00FF;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #ffffff;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #ffffff;
    margin: 10px 0px;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: rgb(194, 245, 249);
    color: #000;
    border-radius: 3px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #7F00FF 0%, #7F00FF 100%);
  }
  #header .logo {
    padding: 20px;
  }
  #header .link {
    font-size: 16px;
    padding: 0 0px;
    color: #ffffff;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 720px) {
    html {
      overflow: auto;
      
    }
    ;
    
  }
.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 80%;
  margin: auto;
  padding: 10px;
  text-align: center;
    font-family: arial;
    border-radius: 5px ;
    background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
    font-size: 26px;
  }
   
  .title {
    color:#008080;
    font-size: 18px;
    margin: auto;
  }
  .shi1222{

    padding-left: 50px;
   
  }
.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 80%;
  margin: auto;
  padding: 10px;
    text-align: center;
    font-family: arial;
    border-radius: 5px ;
    background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));

  }
  .mainContent{
    max-width: 80%;
    
    
    
  }
   
  .shi1{

    padding-left: 50px;
  }
  .title {
    color:#008080;
    font-size: 18px;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: auto;
      text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right,rgb(199, 241, 245),  rgb(242, 212, 248));
      padding: 20px;
      color:rgb(137, 33, 235);
      font-size: medium;
    }
    .card7 {
  
  display:none;
  
    }
    
    .card1 {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;  
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      max-width: 80%;
      margin: auto;
      padding: 10px;
        text-align: center;
        font-family: arial;
        border-radius: 5px ;
        background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
    
        
        
      }
  
      .card2 {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;  
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 100%;
        margin: auto;
          text-align: center;
          font-family: arial;
          border-radius: 5px ;
          background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
          
          
        }
        .card3 {
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;  
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          max-width: 100%;
          margin: auto;
            text-align: center;
            font-family: arial;
            border-radius: 5px ;
            background-image: linear-gradient(to bottom right, rgb(116, 219, 228), rgb(244, 244, 241));
            font-size: medium;
            padding: 20px;
            color:rgb(4, 104, 108);
            
          }
    .mainContent{
      max-width: 80%;
      
      
      
    }
    
    .title {
      color: grey;
      font-size: 18px;
    }
    
.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 80%;
  margin: auto;
  padding: 10px;
    text-align: center;
    font-family: arial;
    border-radius: 5px ;
    background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));

  }
  .mainContent{
    max-width: 80%;
    
    
    
  }
   
  .shi1{

    padding-left: 50px;
  }
  .title {
    color:#008080;
    font-size: 18px;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: auto;
      text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right,rgb(199, 241, 245),  rgb(242, 212, 248));
      padding: 20px;
      color:rgb(137, 33, 235);
      font-size: medium;
    }
    .card7 {
  
  display:none;
  
    }
    
    .card1 {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;  
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      max-width: 80%;
      margin: auto;
      padding: 10px;
        text-align: center;
        font-family: arial;
        border-radius: 5px ;
        background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
    
        
        
      }
  
      .card2 {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;  
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 100%;
        margin: auto;
          text-align: center;
          font-family: arial;
          border-radius: 5px ;
          background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
          
          
        }
        .card3 {
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;  
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          max-width: 100%;
          margin: auto;
            text-align: center;
            font-family: arial;
            border-radius: 5px ;
            background-image: linear-gradient(to bottom right, rgb(116, 219, 228), rgb(244, 244, 241));
            font-size: medium;
            padding: 20px;
            color:rgb(4, 104, 108);
            
          }
    .mainContent{
      max-width: 80%;
      
      
      
    }
    
    .title {
      color: grey;
      font-size: 18px;
    }
    
.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 80%;
  margin: auto;
  padding: 10px;
    text-align: center;
    font-family: arial;
    border-radius: 5px ;
    background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));

  }
  .mainContent{
    max-width: 80%;
    
    
    
  }
   
  .shi1{

    padding-left: 50px;
  }
  .title {
    color:#008080;
    font-size: 18px;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: auto;
      text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right,rgb(199, 241, 245),  rgb(242, 212, 248));
      padding: 20px;
      color:rgb(137, 33, 235);
      font-size: medium;
    }
    .card7 {
  
  display:none;
  
    }
    
    .card1 {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;  
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      max-width: 80%;
      margin: auto;
      padding: 10px;
        text-align: center;
        font-family: arial;
        border-radius: 5px ;
        background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
    
        
        
      }
  
      .card2 {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;  
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 100%;
        margin: auto;
          text-align: center;
          font-family: arial;
          border-radius: 5px ;
          background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
          
          
        }
        .card3 {
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;  
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          max-width: 100%;
          margin: auto;
            text-align: center;
            font-family: arial;
            border-radius: 5px ;
            background-image: linear-gradient(to bottom right, rgb(116, 219, 228), rgb(244, 244, 241));
            font-size: medium;
            padding: 20px;
            color:rgb(4, 104, 108);
            
          }
    .mainContent{
      max-width: 80%;
      
      
      
    }
    
    .title {
      color: grey;
      font-size: 18px;
    }
    
.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 80%;
  margin: auto;
  padding: 10px;
    text-align: center;
    font-family: arial;
    border-radius: 5px ;
    background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));

  }
  .mainContent{
    max-width: 80%;
    
    
    
  }
   
  .shi1{

    padding-left: 50px;
  }
  .title {
    color:#008080;
    font-size: 18px;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: auto;
      text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right,rgb(199, 241, 245),  rgb(242, 212, 248));
      padding: 20px;
      color:rgb(137, 33, 235);
      font-size: medium;
    }
    .card7 {
  
  display:none;
  
    }
    
    .card1 {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;  
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      max-width: 80%;
      margin: auto;
      padding: 10px;
        text-align: center;
        font-family: arial;
        border-radius: 5px ;
        background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
    
        
        
      }
  
      .card2 {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;  
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 100%;
        margin: auto;
          text-align: center;
          font-family: arial;
          border-radius: 5px ;
          background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
          
          
        }
        .card3 {
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;  
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          max-width: 100%;
          margin: auto;
            text-align: center;
            font-family: arial;
            border-radius: 5px ;
            background-image: linear-gradient(to bottom right, rgb(116, 219, 228), rgb(244, 244, 241));
            font-size: medium;
            padding: 20px;
            color:rgb(4, 104, 108);
            
          }
    .mainContent{
      max-width: 80%;
      
      
      
    }
    
    .title {
      color: grey;
      font-size: 18px;
    }
    
.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 80%;
  margin: auto;
  padding: 10px;
    text-align: center;
    font-family: arial;
    border-radius: 5px ;
    background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));

  }
  .mainContent{
    max-width: 80%;
    
    
    
  }
   
  .shi1{

    padding-left: 50px;
  }
  .title {
    color:#008080;
    font-size: 18px;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: auto;
      text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right,rgb(199, 241, 245),  rgb(242, 212, 248));
      padding: 20px;
      color:rgb(137, 33, 235);
      font-size: medium;
    }
    .card7 {
  
  display:none;
  
    }
    
    .card1 {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;  
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      max-width: 80%;
      margin: auto;
      padding: 10px;
        text-align: center;
        font-family: arial;
        border-radius: 5px ;
        background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
    
        
        
      }
  
      .card2 {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;  
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 100%;
        margin: auto;
          text-align: center;
          font-family: arial;
          border-radius: 5px ;
          background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
          
          
        }
        .card3 {
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;  
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          max-width: 100%;
          margin: auto;
            text-align: center;
            font-family: arial;
            border-radius: 5px ;
            background-image: linear-gradient(to bottom right, rgb(116, 219, 228), rgb(244, 244, 241));
            font-size: medium;
            padding: 20px;
            color:rgb(4, 104, 108);
            
          }
    .mainContent{
      max-width: 80%;
      
      
      
    }
    
    .title {
      color: grey;
      font-size: 18px;
    }
    
.button1 {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color:#7F00FF;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}

.button1:hover

    {
        background-color: white;
        color:#7F00FF;
        cursor: pointer;
        text-decoration:none;
        border:1px;
        border-color:blueviolet;
        }
  
.cc22
{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(231, 235, 235);
    color: rgb(18, 17, 17);
    text-align: center;
    padding-left: 75px;
}

.cardProfile {
    
    max-width: 100%;
    margin: auto;
    padding: 10px;
    text-align: center;
      font-family: arial;
      border-radius: 5px ;
      
      font-size: 12px;
    }
     
    
.shi2
{
    padding-left: 76px;
}

.cardProfile {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 80%;
    margin: auto;
    padding: 10px;
    text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
      font-size: 26px;
    }
     
    .title {
      color:#008080;
      font-size: 18px;
      margin: auto;
    }
    .shi1222{
  
      padding-left: 50px;
     
    }

.cardProfile {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: auto;
  text-align: center;
  font-family: arial;
  background-color:whitesmoke;
  color: #008080;
}
 
.title {
  color:#008080;
  font-size: 18px;
}

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: #008080;
  background-color:white;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

/* button:hover, a:hover {
    background-color: white;
    color: black;
  opacity: 0.8;
} */

.shi{

  padding-left: 57px;
}
.he {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 1px;
    color: white;
    background-color:#7F00FF;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    
}

.hee
{
    background-color:#7F00FF;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    width:auto;
    font-size: medium;

}

@media screen and (max-width: 600px) {
  
            .hee,
            .he
             {
                width: 45%;
                font-size: x-small;

            }
        }

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 100%;
  margin: auto;
    text-align: center;
    font-family: arial;
    border-radius: 5px ;
    background-image: linear-gradient(to bottom right,rgb(199, 241, 245),  rgb(242, 212, 248));
    padding: 20px;
    color:rgb(137, 33, 235);
    
  }
  .card7 {

display:none;

  }
  
  .card1 {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 80%;
    margin: auto;
    padding: 10px;
      text-align: center;
      font-family: arial;
      border-radius: 5px ;
      background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
  
      
      
    }

    .card2 {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;  
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      max-width: 100%;
      margin: auto;
        text-align: center;
        font-family: arial;
        border-radius: 5px ;
        background-image: linear-gradient(to bottom right, rgb(19, 215, 228), rgb(244, 244, 241));
        
        
      }
      .card3 {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;  
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 100%;
        margin: auto;
          text-align: center;
          font-family: arial;
          border-radius: 5px ;
          background-image: linear-gradient(to bottom right, rgb(116, 219, 228), rgb(244, 244, 241));
          
          padding: 20px;
          color:rgb(4, 104, 108);
          
        }
  .mainContent{
    max-width: 80%;
    
    
    
  }
  
  .title {
    color: grey;
    font-size: 18px;
  }
  
  button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color:#7F00FF;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  a {
    text-decoration: none;
    font-size: 22px;
    color: black;
  }
  
  /* button:hover, a:hover {
      background-color: white;
      color: black;
    opacity: 0.8;
  } */
  
  .shi1{

    padding-left: 50px;
  }


.uli{
    background-color: bisque;
    border : 2px solid bisque;
    text-align: center;
    
}
