#header {
    position: absolute;
    width: 220px;
    display:flex;
    
  }
  #header .pro-sidebar {
    height: 100%;
    position:fixed;
    
  }
  #header .closemenu {
    color: #ffffff;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;
    top: 55px;
    cursor: pointer;
  }
  #header .pro-sidebar {
    width: 255px;
    min-width: 255px;
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  #header .pro-sidebar-inner {
    background-color: #7F00FF;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #ffffff;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #ffffff;
    margin: 10px 0px;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: rgb(194, 245, 249);
    color: #000;
    border-radius: 3px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #7F00FF 0%, #7F00FF 100%);
  }
  #header .logo {
    padding: 20px;
  }
  #header .link {
    font-size: 16px;
    padding: 0 0px;
    color: #ffffff;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 720px) {
    html {
      overflow: auto;
      
    }
    ;
    
  }